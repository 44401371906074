import React from "react";
import Navbar from "../components/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../components/Footer";
import SliderComponent from "../components/Slider";

const Home = () => {
    return (
        <div>
            <Navbar />
            <SliderComponent />

            {/* New Section */}
            <section className="py-16 bg-gray-100">
                <div className="container px-4 mx-auto text-center">
                    <h2 className="mb-8 text-4xl font-bold text-gray-800">
                        Welcome to YesUp
                    </h2>
                    <p className="mb-8 text-xl text-gray-600">
                        Welcome to YesUp, where innovation meets excellence! We
                        are thrilled to have you here and excited to showcase
                        our cutting-edge solutions and services tailored to meet
                        your needs. At YesUp, our mission is to provide you with
                        unparalleled experiences through our diverse range of
                        products designed to make a positive impact on your
                        daily life.
                    </p>
                    <p className="mb-8 text-xl text-gray-600">
                        Explore our platform to discover a wealth of features
                        that enhance productivity, streamline operations, and
                        bring convenience right to your fingertips. From
                        intuitive interfaces to powerful functionalities, we’ve
                        crafted every aspect of YesUp with you in mind.
                    </p>
                    <p className="mb-8 text-xl text-gray-600">
                        Our dedicated team is always here to support you and
                        ensure you make the most out of our offerings. We
                        believe in continuous improvement and innovation,
                        striving to bring you the latest advancements and best
                        practices in the industry.
                    </p>
                    <p className="mb-8 text-xl text-gray-600">
                        Whether you're a new user or a long-time partner, we
                        invite you to explore our resources, engage with our
                        community, and take full advantage of what YesUp has to
                        offer. Stay tuned for updates, as we are constantly
                        working to bring you new and exciting features.
                    </p>
                    <p className="mb-8 text-xl text-gray-600">
                        Thank you for choosing YesUp. We are excited to embark
                        on this journey with you and look forward to supporting
                        your success every step of the way. Together, let's
                        achieve great things and make a difference!
                    </p>
                </div>
            </section>

            <section className="py-16 bg-gray-200">
                <div className="container px-4 mx-auto text-center">
                    <h2 className="mb-8 text-4xl font-bold text-gray-900 font-pacifico">
                        Stay Updated
                    </h2>
                    <p className="mb-12 text-xl text-gray-700">
                        Subscribe to our newsletter and be the first to know
                        about our latest updates, special offers, and exclusive
                        content.
                    </p>
                    <form className="max-w-md mx-auto">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            className="w-full px-4 py-2 mb-4 text-gray-800 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Subscribe
                        </button>
                    </form>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Home;
