import React, { useState } from "react";
import Navbar from "../components/Navbar";
import productData from "../assets/data/products.json"; // Adjust path as necessary
import Footer from "../components/Footer";
import cloudImage from "../assets/images/cloudimage1.png";

const Products = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTimeout(() => {
            setSelectedProduct(null);
        }, 300); // Adjust the timing to match your animation duration
    };

    return (
        <div>
            <Navbar />
            <div className="container px-4 py-8 mx-auto mt-24">
                <div className="mt-2 mb-16 text-4xl font-bold text-center font-pacifico">
                    Our Product
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {productData.map((product) => (
                        <div
                            key={product.id}
                            className="p-4 transition-transform duration-300 ease-in-out transform bg-gray-200 bg-center bg-cover rounded-lg shadow-md hover:scale-105 hover:shadow-lg"
                            style={{
                                backgroundImage: `url(${cloudImage})`,
                                backgroundSize: "110%", // Adjust the percentage to control the image size
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <img
                                src={product.image}
                                alt={product.name}
                                className="object-cover w-full rounded-t-lg cursor-pointer h-90"
                            />
                            <h2 className="flex justify-center mt-2 text-2xl font-semibold">
                                {product.name}
                            </h2>
                            <div className="mt-2">
                                {/* Variations can be added here if needed */}
                            </div>
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={() => openModal(product)}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    View More Details
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {selectedProduct && (
                <div
                    className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-300 ${
                        isModalOpen ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <div
                        className={`relative z-10 flex w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 ${
                            isModalOpen ? "scale-100" : "scale-95"
                        }`}
                    >
                        <button
                            onClick={closeModal}
                            className="absolute flex items-center justify-center p-1 px-2 text-white bg-blue-600 rounded-md top-4 right-4 hover:text-white"
                        >
                            &times;
                        </button>
                        <div className="flex-shrink-0 w-1/3">
                            <img
                                src={selectedProduct.image}
                                alt={selectedProduct.name}
                                className="object-cover w-full h-full rounded-lg"
                            />
                        </div>
                        <div className="flex-grow ml-6">
                            <h2 className="mb-4 text-2xl font-bold">
                                {selectedProduct.name}
                            </h2>
                            <p className="mb-4 text-gray-700">
                                {selectedProduct.description}
                            </p>
                            <div>
                                <span className="font-medium">Variations:</span>
                                <ul className="pl-5 list-disc">
                                    {selectedProduct.variations.map(
                                        (variation, index) => (
                                            <li key={index}>{variation}</li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className="fixed inset-0 z-0 bg-black opacity-50"
                        onClick={closeModal}
                    ></div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default Products;
