import React, { useState, useEffect } from "react";
import jeraaImage from "../assets/images/products/jeera.png";
import pineappleImage from "../assets/images/products/pineapple.png";
import colaImage from "../assets/images/products/cola.png";
import "./Slider.css"; // Import the CSS file for animations

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            imageSrc: jeraaImage,
            captionTitle: "Jeera Ko Pio Maje se..",
            backgroundColor: "#FFEB3B", // Yellow
        },
        {
            imageSrc: pineappleImage,
            captionTitle: "Test Kaha Hai\nTest Yaha Hai..", // Apply the font here
            backgroundColor: "#FF5722", // Orange
        },
        {
            imageSrc: colaImage,
            captionTitle: "Kya Swaad Hai Aaha Maja Aagaya..",
            backgroundColor: "#03A9F4", // Light Blue
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        }, 5000); // Ensure this matches the animation duration

        return () => clearInterval(interval);
    }, [slides.length]);

    const handlePrev = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    const handleNext = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
    };

    return (
        <div className="relative w-full mx-auto mt-20 max-w-8xl">
            <div className="relative flex items-center justify-center overflow-hidden h-128">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`${
                            index === currentSlide ? "block" : "hidden"
                        } transition-opacity duration-700 ease-in-out flex justify-center items-center w-full`}
                        style={{ backgroundColor: slide.backgroundColor }} // Apply background color here
                    >
                        <div>
                            <h5 className="mb-4 text-6xl font-pacifico">
                                {slide.captionTitle}
                            </h5>
                        </div>
                        <img
                            src={slide.imageSrc}
                            className="object-contain h-80 md:h-96 slide-animation"
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                ))}
            </div>

            <button
                onClick={handlePrev}
                className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-800 bg-opacity-50 rounded-full top-1/2 left-2"
            >
                <span className="sr-only">Previous</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
            </button>

            <button
                onClick={handleNext}
                className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-800 bg-opacity-50 rounded-full top-1/2 right-2"
            >
                <span className="sr-only">Next</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                    />
                </svg>
            </button>
        </div>
    );
};

export default Slider;
