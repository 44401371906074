import React from "react";
import Navbar from "../components/Navbar";
import missionImage from "../assets/images/our-mission.png"; // Path to your mission image
import visionImage from "../assets/images/our-vision.png"; // Path to your vision image
import Footer from "../components/Footer";

const AboutUs = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <Navbar />
            <div className="container px-4 py-8 mx-auto mt-24">
                <div className="mt-2 mb-16 text-4xl font-bold text-center font-pacifico">
                    About YesUp
                </div>
                <p className="max-w-2xl mx-auto text-base text-gray-700 sm:text-lg">
                    We are a dedicated team committed to providing high-quality
                    services and products that exceed our customers'
                    expectations. Discover more about our mission, vision, and
                    the amazing team behind our success.
                </p>

                {/* Mission and Vision Section */}
                <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
                    {/* Mission Section */}
                    <div className="flex flex-col items-center p-4 rounded-lg md:p-8">
                        <div className="w-full mb-4 md:mb-8">
                            <img
                                src={missionImage}
                                alt="Our Mission"
                                className="object-cover w-full rounded-lg h-60 md:h-72 lg:h-80" // Adjusted height for responsiveness
                            />
                        </div>
                        <div className="text-center">
                            <h2 className="mb-2 text-xl font-semibold text-gray-800 md:text-2xl">
                                Our Mission
                            </h2>
                            <p className="text-base leading-relaxed text-gray-600 md:text-lg">
                                Our mission is to deliver exceptional products
                                and services that meet the highest standards. We
                                are driven by a passion for excellence and a
                                commitment to continuous improvement.
                            </p>
                        </div>
                    </div>

                    {/* Vision Section */}
                    <div className="flex flex-col items-center p-4 rounded-lg shadow-lg md:p-8">
                        <div className="w-full mb-4 md:mb-8">
                            <img
                                src={visionImage}
                                alt="Our Vision"
                                className="object-cover w-full rounded-lg h-60 md:h-72 lg:h-80" // Adjusted height for responsiveness
                            />
                        </div>
                        <div className="text-center">
                            <h2 className="mb-2 text-xl font-semibold text-gray-800 md:text-2xl">
                                Our Vision
                            </h2>
                            <p className="text-base leading-relaxed text-gray-600 md:text-lg">
                                Our vision is to be a leader in our industry,
                                recognized for our innovative solutions and
                                outstanding customer service. We aim to create
                                lasting value for our clients and stakeholders.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
