import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        mobile: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors({ ...errors, [id]: "" }); // Clear error when field changes
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let hasErrors = false;
        const newErrors = {};

        // Validation
        if (!formData.name) {
            newErrors.name = "Name is required";
            hasErrors = true;
        }
        if (!formData.mobile) {
            newErrors.mobile = "Mobile number is required";
            hasErrors = true;
        }
        if (!formData.message) {
            newErrors.message = "Message is required";
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors(newErrors);
            return;
        }

        console.log("Form Data:", formData);
        // Add additional form submission logic here
    };

    return (
        <div>
            <Navbar />
            <div className="relative pt-16 overflow-hidden bg-gray-200">
                <div className="container relative px-4 py-16 mx-auto">
                    <h1 className="mt-2 mb-16 text-4xl font-bold text-center font-pacifico">
                        Contact Us
                    </h1>
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                        <div className="space-y-10">
                            <div>
                                <h2 className="text-2xl font-semibold text-gray-900">
                                    Office Address
                                </h2>
                                <p className="mt-2 font-semibold text-gray-900">
                                    Silvostar Beverages,
                                    <br />
                                    Nr. Telephone Exchange,
                                    <br />
                                    Dhangdhra, Hadvad,
                                    <br />
                                    Dist. Morbi,
                                    <br />
                                    Gujarat - 363330
                                </p>
                            </div>
                            <div>
                                <h2 className="text-2xl font-semibold text-gray-900">
                                    Plant Address
                                </h2>
                                <p className="mt-2 font-semibold text-gray-900">
                                    Silvostar Beverages,
                                    <br />
                                    Radhe Industrial,
                                    <br />
                                    Plot No 6,
                                    <br />
                                    Nr. Shine Cot-Spin Pvt. Ltd,
                                    <br />
                                    Maliya Road,
                                    <br />
                                    Hadvad, Dist. Morbi,
                                    <br />
                                    Gujarat - 363330
                                </p>
                            </div>
                        </div>
                        <div className="relative p-8 bg-white rounded-lg shadow-lg">
                            <h2 className="mb-4 text-2xl font-semibold">
                                Get in Touch
                            </h2>
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="block w-full px-4 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.name && (
                                        <p className="mt-1 text-sm text-red-600">
                                            {errors.name}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label
                                        htmlFor="mobile"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Mobile
                                    </label>
                                    <input
                                        type="tel"
                                        id="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        className="block w-full px-4 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.mobile && (
                                        <p className="mt-1 text-sm text-red-600">
                                            {errors.mobile}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Email (Optional)
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="block w-full px-4 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.email && (
                                        <p className="mt-1 text-sm text-red-600">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows="4"
                                        className="block w-full px-4 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    ></textarea>
                                    {errors.message && (
                                        <p className="mt-1 text-sm text-red-600">
                                            {errors.message}
                                        </p>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm md:w-auto hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
